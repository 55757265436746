import * as React from "react"
type Props = {
    children: string
}

const CssHeader = ({children}: Props) => (
<div className="relative text-center font-display uppercase text-5xl leading-normal py-6">
  <h1>{children}</h1>
</div>
)

export default CssHeader