import * as React from "react"
import SEO from "../components/layout/seo"
import CssHeader from "../components/typography/css-header"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import EventCard from "../components/events/event-card"

const Events = ({data, context}) => {
  const allEvents = data.allMarkdownRemark.edges
  return (
   <Layout>
     <SEO title="Events" />
     <CssHeader>Events</CssHeader>
     <h2 className="text-4xl font-display pt-12">Upcoming</h2>
     <div className="flex flex-wrap gap-4">
        {allEvents.map(event => <EventCard 
          title={event.node.frontmatter.title}
          className="flex-none bg-neutral-50/25 fade"
          date={event.node.frontmatter.date}
          location={event.node.frontmatter.location}
          key={event.node.fields.slug}
        />)}
      </div>
   </Layout>
  )
}

export const query = graphql`
query GetEventsPaginated($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {fields: {collection: {eq: "events"}}, frontmatter: {published: {eq: true}}}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        frontmatter {
          title
          date(fromNow: true)
          banner
          location
        }
        fields {
          slug
        }
      }
    }
  }
}
`

export default Events
